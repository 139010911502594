#login {
  position: relative;
  min-height: 100vh;
  background-color: $transparent-grey;

  .background-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;

    .background-1 {
      min-height: 100vh;
      background-color: $primary-light;
      width: 70%;

      @media #{$tablet} {
        width: 100%;
      }
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      inset: 0;
      background-image: url('../assets/ring.svg');
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: 54% -21vh;

      @media #{$tablet} {
        background-size: 363px;
        background-position: calc(100% + 245px) -200px;
      }

      @media #{$mobile} {
        background-size: 363px;
        background-position-x: calc(100% + 230px) -230px;
      }

      @media #{$small-mobile} {
        background-position-y: -255px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      inset: 0;
      background-image: url('../assets/ring.svg');
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: -14% 71vh;

      @media #{$tablet} {
        background-size: 474px;
        background-position: -150px calc(100% + 300px);
      }

      @media #{$mobile} {
        background-size: 474px;
        background-position: -150px calc(100% + 365px);
      }

      @media #{$small-mobile} {
        background-position-x: -200px;
      }
    }
  }

  .language-selector-container {
    button {
      position: fixed;
      z-index: 100;
      top: 1.875rem;
      right: 6.25rem
    }

    @media #{$tablet} {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3rem 0 0 0;

      button {
        position: relative;
        z-index: 100;
        top: 0;
        right: 0;  
      }
    }
  }
}

.login-content {
  position: absolute;
  background-color: transparent;
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  

  &.services-content,
  &.hotels-content {
    strong {
      br {
        display: none;
      }
    }
  }

  &.hotels-content,
  &.travels-content,
  &.expenses-content {
    @media #{$mobile} {
      margin: 1.5rem 0 0 0;
    }
  }

  @media #{$mobile} {
    padding-bottom: 3rem;
    background-color: $primary-light;
  }

  h1 {
    font-family: $secondary-font;
    color: $secondary;
    font-size: 2.1875rem;
    font-weight: 300;
    max-width: 38%;
    margin-left: 10%;
    line-height: 1.2;

    @media #{$tablet} {
      font-size: 2.625rem;
      max-width: 48%;
      margin-left: 0;
      text-align: center;
      margin-bottom: 3rem;
      line-height: 1.4;
      font-size: 2.25rem;
    }

    @media #{$mobile} {
      margin-bottom: 1rem;
      max-width: 77%;
    }

    @media #{$small-mobile} {
      font-size: 1.625rem;
      max-width: 84%;
    }

    strong {
      font-size: 4.3125rem;
      font-weight: 700;
      display: flex;
      gap: 1rem;
      width: 100%;
      flex-wrap: wrap;

      div {
        margin-bottom: -1rem;
      }

      @media #{$tablet} {
        font-size: 2.25rem;
        justify-content: center;
        gap: 0.5rem;

        div {
          margin-bottom: -0.5rem;
        }
      }
    }
  }

  .login-form {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 3px 6px $form-shadow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    margin: 0 4rem;
    position: absolute;
    right: 10%;

    @media #{$tablet} {
      position: unset;
    }

    &.services-form,
    &.hotels-form,
    &.travels-form,
    &.expenses-form {
      width: 30%;

      @media #{$tablet} {
        width: 60%;
      }

      @media #{$mobile} {
        width: 85%;
      }
    }

    &.restaurants-form {
      min-width: 25%;
      margin-left: 9.5rem;
      margin-right: 3.5rem;
      right: 12%;

      .navigation-section {
        margin-top: 1rem;
      }

      .error-message {
        text-align: center;
        height: 12.5rem;
      }

      @media #{$tablet} {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media #{$tablet} {
      box-sizing: border-box;
      width: 48%;
      padding: 6%;
      margin: 0;
    }

    @media #{$mobile} {
      width: 82%;
      padding: 8%;
    }

    @media #{$small-mobile} {
      padding: 7%;

      svg:not(.MuiSvgIcon-root) {
        width: 90%;
      }
    }
  }

  .form-title {
    text-align: center;
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 1.5rem;

    @media #{$tablet} {
      font-size: 1.0625rem;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }

  .submit-button {
    color: $white;
    background-color: $secondary;
    width: 100%;
  }

  .recaptcha {
    transform: scale(0.77);
    transform-origin: 0 center;

    @media #{$tablet} {
      transform: scale(0.55);
      margin: -0.5rem 0;
    }

    label {
      font-family: 'Rubik';
    }
  }

  @media #{$tablet} {
    gap: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5rem;
  }

  .form-grid {
    display: grid;
    gap: 1rem;

    @media #{$tablet} {
      grid-template-columns: 1fr;
    }

    @media #{$desktop} {
      grid-template-columns: 1fr 1fr;
    }
  }

  .navigation-section {
    display: flex;
    gap: 0.8rem;

    .go-back-button {
      padding: 0 2.5rem;
      border-color: $border-grey;

      @media #{$tablet} {
        padding: 0;
      }
    }

    .arrow {
      display: none;

      @media #{$tablet} {
        display: flex;
      }
    }

    .text {
      @media #{$tablet} {
        display: none;
      }
    }
  }
}
