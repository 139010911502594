#success,
#success-payment {
  background-color: $primary-light;

  .text {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    inset: 0;
    background-image: url('../assets/ring.svg');
    background-repeat: no-repeat;
    background-size: 587px;
    background-position: calc(100% + 95px) -200px;

    @media #{$tablet} {
      background-size: 363px;
      background-position: calc(100% + 80px) -240px;
    }

    @media #{$mobile} {
      background-size: 363px;
      background-position: calc(100% + 120px) -250px;
    }

    @media #{$small-mobile} {
      background-position: calc(100% + 130px) -300px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    inset: 0;
    background-image: url('../assets/ring.svg');
    background-repeat: no-repeat;
    background-size: 474px;
    background-position: 120px calc(100% + 350px);

    @media #{$tablet} {
      background-size: 363px;
      background-position: -140px calc(100% + 180px);
    }

    @media #{$small-mobile} {
      background-position: -160px calc(100% + 235px);
    }
  }

  .button-wrap {
    color: $white;

    .icon-wrap {
      border: 1px solid $primary-border;
      transition: all 1s;
    }

    &:hover {
      @media #{$desktop} {
        .icon-wrap {
          background-color: $secondary;
          border-color: $secondary;
          padding: 2.125rem;
          transform: translateX(-19px);
        }
      }
    }
  }

  .return-site-button-container {
    svg {
      path {
        fill: $white;
      }
    }
  }
}

#success {
  .text-container {
    max-width: 38%;

    @media #{$tablet} {
      max-width: 42%;
    }

    @media #{$mobile} {
      max-width: 75%;
      margin-top: 10%;
    }
  }

  .content {
    margin-left: 0;
  }
}
