#error-commerce,
#error-payment {
  background-image: url('../assets/bg-light.svg');

  h1 {
    max-width: 95%;

    @media #{$tablet} {
      max-width: 100%;
    }
  }

  .text {
    width: 100%;

    a {
      font-weight: 400;
      color: $primary-light;
    }
  }

  br {
    margin-top: 0;
  }

  .button-wrap {
    color: $primary-light;

    .icon-wrap {
      border: 1px solid $primary-border;
      transition: all 1s;

      svg {
        path {
          fill: $primary-light;
        }
      }
    }

    &:hover {
      @media #{$desktop} {
        .icon-wrap {
          background-color: $secondary;
          border-color: $secondary;
          padding: 2.125rem;
          transform: translateX(-19px);
        }
      }
    }
  }

  .return-site-button-container {
    .button {
      color: $accent;
    }

    svg {
      path {
        fill: $accent;
      }
    }
  }
}

#error-commerce {
  &.message {
    justify-content: center;

    .text-container {
      @media #{$tablet} {
        max-width: unset;
      }
    }
  }

  h1 {
    max-width: 100%;
  }

  .text {
    max-width: 100%;
  }

  .content {
    width: $screen-width;
    max-width: 100%;
    margin: 0 auto;
    gap: 10rem;

    @media #{$tablet} {
      justify-content: flex-start;
      margin-top: 12rem;
      width: unset;
      gap: 0;
    }
  }

  .return-site-button-container {
    @media #{$tablet} {
      margin-top: 0;
    }
  }
}
