.email-step {
  width: 90%;
  border-radius: 9px;
  border: 1px solid $border-grey-container;
  font-family: 'Rubik';

  h6 {
    margin-bottom: 0.875rem;
  }

  p {
    width: 90%;
    color: $light-grey;
    margin: 0 0 1.4375rem;
    line-height: 1.4rem;
    font-size: 0.875rem;
  }

  .email-container {
    padding: 1.5rem;
  }

  form {
    width: 60%;

    @media #{$tablet} {
      width: 100%;
    }
  }
}
