.single-payment-method-step,
.payment-methods-step {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  width: 90%;

  @media #{$tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 9px;
    gap: 1rem;
    width: 85%;
  }

  h6 {
    font-weight: 500;

    @media #{$tablet} {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }
  }

  .payment-methods-list-container {
    padding: 1.5rem;
    border-radius: 9px;
    border: 1px solid $border-grey-container;

    @media #{$tablet} {
      width: 100%;
      padding: unset;
    }

    p {
      @media #{$tablet} {
        padding: 0 1.5rem;
      }
    }

    .payment-methods-list {
      display: flex;
      flex-direction: column;
      padding: 1rem 0 0 0;

      @media #{$tablet} {
        padding: 1.5rem;
      }

      .payment-method {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0.8rem;
        cursor: pointer;
        user-select: none;
        border: 1px solid white;
        border-bottom: 1px solid $border-grey;

        &:hover {
          background-color: $light-green;
        }

        &:last-child:not(.selected) {
          border-bottom: 0;
        }

        img {
          border: 1px solid $shadow-grey;
          padding: 0 0.2rem;
          max-height: 3.4375rem;
          width: 5rem;
        }

        .data {
          display: flex;
          flex-direction: column;
          font: normal normal normal 12px/14px Rubik;
          gap: 0.2rem;

          .commission {
            color: $light-grey;
          }
        }
      }

      .selected {
        background-color: $light-green;
        border: 1px solid $green;
      }

      .disabled {
        opacity: 0.45;
        pointer-events: none;
      }
    }
  }
}

.single-payment-method {
  background-color: $light-green;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.6rem;

  @media #{$tablet} {
    margin-bottom: 1rem;
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    font: normal normal normal 0.75rem/0.875rem Rubik;

    span {
      color: $light-grey;
    }
  }
}

.details-container {
  border-radius: 9px;
  border: 1px solid $border-grey-container;
  padding: 1.5rem;

  @media #{$tablet} {
    width: 100%;
    padding: 0;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media #{$tablet} {
    padding: 1.5rem;
  }

  h6 {
    color: $light-grey;
    margin-bottom: 0.6rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p:last-of-type {
      text-align: end;
    }
  }

  .text-data {
    text-align: end;
  }

  .with-separator {
    border-bottom: 1px solid $border-grey;
    padding-bottom: 1rem;
  }

  .total {
    color: $blue;
    font: normal normal bold 0.875rem/1.0625rem Rubik;
    letter-spacing: 0.1px;
    text-align: end;

    @media #{$mobile} {
      display: none;
    }
  }

  .commission {
    @media #{$mobile} {
      display: none;
    }
  }
}

.disabled-text {
  font: normal normal italic 0.75rem Rubik;
  margin: 0.3rem 0 0 0;
}

.detail-column {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

.single-payment-method-step {
  border: 1px solid $border-grey-container;

  .payment-methods-list-container {
    @media #{$tablet} {
      border: unset;
    }

    .payment-methods-list {
      padding-bottom: 0;
    }
  }

  .details {
    padding-top: 0;
  }

  .details-container {
    @media #{$tablet} {
      border: unset;
    }
  }
}
