#not-found,
#maintenance-page {
  background-image: url('../assets/bg-light.svg');
  background-size: cover;
  overflow: hidden;

  .content {
    flex-direction: column;
    align-items: flex-start;

    @media #{$tablet} {
      align-items: center;
    }
  }

  .text-container {
    max-width: 60%;

    .text {
      max-width: unset;

      a {
        font-weight: 400;
        color: $primary-light;
      }
    }
  }

  .return-site-button-container {
    margin-left: 0;

    .button {
      color: $accent;
    }

    svg {
      path {
        fill: $accent;
      }
    }

    @media #{$tablet} {
      margin-top: 0;
    }
  }

  .not-found-image-container,
  .maintenance-image-container {
    background-image: url('../assets/orange-building.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 30vw;
    height: 100vh;
    position: absolute;
    right: 0;

    img {
      max-height: 100vh;
      position: relative;
      right: 50%;
      bottom: 0;
      height: inherit;
    }

    @media #{$tablet} {
      display: none;
    }
  }
}

#not-found {
  .text-container {
    max-width: unset;
  }

  .text {
    max-width: unset;
  }
}
