.button {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: $white;
  font-family: 'Titillium Web';

  & canvas {
    position: absolute;
    border-radius: 50%;
  }

  & * {
    pointer-events: none;
  }
}

.curvy-text {
  position: absolute;
  font-size: 5.25rem;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 1;
  word-spacing: 20px;

  &.rotate {
    animation-name: spin;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

// #region rotation animation

$rotate-start: 'rotate(0deg)';
$rotate-end: 'rotate(360deg)';

@-ms-keyframes spin {
  from {
    -ms-transform: #{$rotate-start};
  }
  to {
    -ms-transform: #{$rotate-end};
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: #{$rotate-start};
  }
  to {
    -moz-transform: #{$rotate-end};
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: #{$rotate-start};
  }
  to {
    -webkit-transform: #{$rotate-end};
  }
}

@keyframes spin {
  from {
    transform: #{$rotate-start};
  }
  to {
    transform: #{$rotate-end};
  }
}

// #endregion
