.stepper-paper {
  border-radius: 9px;
  box-shadow: 0px 1px 4px $shadow-grey;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 0;
  margin-bottom: 2rem;

  @media #{$tablet} {
    gap: 1.5rem;
    padding: 1.5rem 0;
    margin-bottom: 6rem;
  }

  @media #{$mobile} {
    margin-bottom: 12rem;
  }
}

.stepper-container {
  width: 90%;
  font-size: 0.75rem;
  font-weight: 600;
  gap: 2rem;
  padding-left: 0;

  @media #{$tablet} {
    width: 80%;
    justify-content: center;
  }

  @media #{$mobile} {
    width: 80%;
    justify-content: space-between;
  }

  @media #{$small-mobile} {
    gap: 0rem;
  }

  & .MuiStepLabel-label {
    @media #{$tablet} {
      display: none;
    }
  }
}
