#restaurants {
  width: 90%;

  @media #{$tablet} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
  }

  @media #{$mobile} {
    gap: 1rem;
  }

  .payment-methods-step,
  .payment-information-step {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
    width: 100%;

    @media #{$tablet} {
      display: flex;
      width: 85%;
      align-items: center;
    }
  }

  h6 {
    @media #{$tablet} {
      padding: 0;
    }
  }

  .row {
    h6 {
      margin-top: 1rem;
    }
  }

  .email-step {
    width: 100%;

    form {
      @media #{$tablet} {
        width: 100%;
      }
    }

    @media #{$tablet} {
      width: 85%;
    }
  }

  #snackbar {
    margin: 0;
    padding: 1rem 1.5rem;
    width: unset;
    max-width: unset;

    @media #{$tablet} {
      width: 85%;
    }

    .container {
      width: 100%;
    }
  }

  h6 {
    font-weight: 500;
  }

  .charge-detail {
    padding: 2rem;
    border-radius: 9px;
    border: 1px solid $border-grey-container;

    h6 {
      color: $secondary;
    }

    @media #{$tablet} {
      width: 85%;
      padding: 1.5rem;
    }
  }

  .commission-detail {
    padding: 2rem;
    background-color: $white;
    border-radius: 9px;
    border: 1px solid $border-grey-container;

    .total {
      padding-top: 0.5rem;
    }

    @media #{$tablet} {
      display: none;
    }
  }
  .commission-text {
    font: normal normal normal 0.875rem $primary-font;
    color: $lightest-grey;
  }

  .details {
    gap: 1rem;

    .total-row {
      p {
        font: normal normal bold 1rem $primary-font;
        color: $blue;
      }
    }
  }
}

#restaurants-debt-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.row-title {
  font: normal normal normal 0.75rem/0.875rem $primary-font;
  color: $light-grey;
}

.row-data {
  text-align: right;
}

.edit-restaurant-dialog {
  .MuiPaper-root {
    max-width: unset;
    text-align: center;
    padding: 1rem 1rem;

    @media #{$mobile} {
      text-align: center;
      max-width: 78%;
    }
  }

  .MuiDialogActions-root {
    padding: 1rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media #{$mobile} {
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }

    button {
      font-family: $primary-font;
      font-weight: 400;

      @media #{$mobile} {
        font-size: 0.875rem;
        width: 100%;
        margin-left: 0;
      }
    }
  }

  h2 {
    font-family: $primary-font;
    font-size: 1.5rem;
    font-weight: 700;
    position: relative;
    padding-top: 1.5rem;
    line-height: normal;

    span {
      margin-right: 1.5rem;
    }

    @media #{$mobile} {
      font-size: 1.25rem;
      padding-bottom: 0.5rem;
    }

    button {
      position: absolute;
      right: 1rem;
      top: 0;

      @media #{$mobile} {
        right: 0.8rem;
      }

      &:hover {
        background-color: unset;
      }

      svg {
        position: absolute;
        top: -15px;
        right: -10px;
        path {
          fill: $lightest-grey;
        }
      }
    }
  }

  p {
    color: $light-grey;
    font-weight: 400;
    margin: 0;
    font-family: $primary-font;

    @media #{$mobile} {
      line-height: 1.25rem;
      font-size: 0.75rem;
    }
  }
}
