@import './variables.module';

.landing {
  background-color: $landing-background-color;
  position: relative;
  min-height: 100vh;
  display: grid;
  justify-items: center;
  align-items: center;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::before {
    background-image: url('../assets/ring.svg');
    position: absolute;
    display: block;
    top: -30%;
    right: -15%;
    width: 35rem;
    height: 35rem;

    @media #{$tablet} {
      display: none;
    }
  }

  &::after {
    background-image: url('../assets/ring.svg');
    position: absolute;
    display: block;
    bottom: -30%;
    left: 2%;
    z-index: 1;
    width: 35rem;
    height: 35rem;

    @media #{$mobile} {
      bottom: -40%;
      left: -80%;
    }
  }

  .landing-container {
    display: flex;
    flex-direction: column;

    h2 {
      color: $secondary;
    }

    &.desktop {
      flex-direction: column;
      padding: 0 12%;
      min-height: 80vh;

      .landing-texts {
        padding-top: 6rem;

        .landing-instruction {
          margin-top: 1rem;
        }
      }

      .commerces-container {
        padding: 2rem;
        z-index: 2;
      }
    }

    &.mobile {
      min-height: 90vh;
      padding: 1rem 2rem;

      .commerces-container {
        padding: 2rem 1rem;
        flex-grow: 1;
        z-index: 2;
        row-gap: 0.5rem;

        @media #{$small-mobile} {
          padding: 1rem;
        }
      }

      .landing-texts {
        align-items: start;
        background-image: url('../assets/ring.svg');
        background-repeat: no-repeat;
        background-position: -15% 170%;
        justify-content: end;
        min-height: 27vh;

        @media #{$small-mobile} {
          min-height: auto;
          padding-bottom: 1rem;
          justify-content: center;
          min-height: 30vh;
        }

        .landing-instruction {
          line-height: 1.5;

          @media #{$small-mobile} {
            font-size: 1rem;
            line-height: 1.5;
          }
        }
      }

      h2 {
        font-size: 2rem;

        @media #{$small-mobile} {
          font-size: 1.5rem;
          line-height: 2;
        }
      }

      @media #{$tablet} {
        width: $tablet-content-width;
      }

      @media #{$mobile} {
        width: unset;
      }
    }
    
    .language-selector-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 1.75rem 0;

      button {
        position: relative;
        z-index: 100;
      }
    }

    .landing-content {
      display: flex;
      flex-direction: row;

      @media #{$tablet} {
        flex-direction: column;
      }
    }

    .landing-texts {
      background-color: $primary-light;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .commerces-container {
      background-color: $white;
      padding: 2rem 3.125rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media #{$mobile} {
        min-height: auto;
      }

      .vertical-box {
        justify-content: flex-start;
        align-items: center;
        column-gap: 1rem;
        align-items: center;
        padding: 0.5rem 1rem;
        padding-right: 0;
        margin: 1rem 0;
        cursor: pointer;
        background: $translucent-grey 0% 0% no-repeat padding-box;
        border-radius: 15px;

        &:hover,
        &.selected {
          background-color: $light-grayish-orange;

          img {
            padding-right: 2rem;

            @media #{$mobile} {
              padding-right: 1rem;
            }
          }
        }

        .icon-container {
          width: 3.8rem;
          display: flex;
          justify-content: center;
        }

        .vertical-type-box {
          justify-content: space-between;
          width: 100%;
          align-items: center;
          column-gap: 1rem;
        }

        .vertical-info {
          .vertical-title,
          .vertical-subtitle {
            font: normal normal normal 1.125rem/1.75rem $primary-font;
          }

          .vertical-title {
            color: $secondary;
          }

          .vertical-subtitle {
            color: $dark-grayish-blue;
          }

          @media #{$mobile} {
            .vertical-title,
            .vertical-subtitle {
              font-size: 0.875rem;
              line-height: 1.1875rem;
            }
          }

          @media #{$small-mobile} {
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;

            .vertical-title,
            .vertical-subtitle {
              font-size: 0.75rem;
              line-height: 1.25rem;
            }
          }
        }
      }

      .vertical-selection {
        font: normal normal 500 1.125rem/2.25rem $primary-font;
        letter-spacing: 0.07px;
        margin-top: 1rem;
        color: $secondary;
      }

      button {
        margin-top: 1rem;
      }
    }

    .desktop-width {
      width: 20.5rem;
    }

    .mobile-width {
      width: unset;
    }

    .landing-instruction {
      font: normal normal bold 1.25rem/2.51rem $secondary-font;
      color: $secondary;
    }
  }
}
