#snackbar {
  color: $yellow;
  background-color: $yellow-transparent;
  border: 1px solid $yellow-light;
  border-radius: 5px;
  font-family: $primary-font;
  padding: 1rem 0;
  margin: 1.5rem auto;
  width: $screen-width;
  max-width: 80%;

  @media #{$tablet} {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  @media #{$mobile} {
    width: unset;
    max-width: 85%;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 95%;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    @media #{$mobile} {
      align-items: start;
      gap: 1rem;
    }
  }

  .text-box {
    line-height: 1.4;
    font-size: 1rem;
    font-weight: 500;

    @media #{$small-mobile} {
      font-size: 0.875rem;
    }

    br {
      display: none;

      @media #{$mobile} {
        display: block;
      }
    }
  }

  button {
    opacity: 50%;
    padding: 0;
    display: flex;
    justify-content: end;
  }
}
