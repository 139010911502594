$background-color: #f4f7fc;
$landing-background-color: #e7e9eb;
$white: #ffffff;
$primary: #ff8b22;
$primary-light: #ffb359;
$primary-border: #ffcc90;
$light-grayish-orange: #fdf5eb;
$secondary: #081e50;
$accent: #00c6c3;
$grey: #131523;
$light-grey: #7e84a3;
$lightest-grey: #909090;
$light-greyish: #dde0ef;
$disabled-grey-border: #e0e0e0;
$disabled-grey: #ebebeb;
$dark-grayish-blue: #737998;
$line-grey: #707997;
$grey-text: #666;
$dark-grey: #5a607f;
$shadow-grey: #15223214;
$border-grey: #70799733;
$border-grey-container: #70799726;
$border-button-grey: #D7DBEC;
$black: #171725;
$dark-greyblue: #343A40;
$translucent-grey: #f5f5f5;
$transparent-grey: #f4f7fc;
$placeholder-grey: #a2a2a2;
$form-shadow: #00000029;
$blue: #004889;
$dark-blue: #000c55;
$green: #00b2b8;
$light-green: #00b2b81a;
$rotate-button-hover-primary: #ffb359aa;
$rotate-button-hover-secondary: #ffffff60;
$input-border: #c4c4c4;
$yellow: #c38900;
$yellow-light: #ffca4d;
$yellow-transparent: #ffca4d40;
$red: #ff0000;

$primary-font: Rubik;
$secondary-font: Titillium Web;

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400&display=swap');

$small-mobile: 'only screen and (max-width: 376px)';
$mobile: 'only screen and (max-width: 767px)';
$tablet: 'only screen and (max-width: 899px)';
$desktop: 'only screen and (min-width: 900px)';

$tablet-content-width: 32rem;

$zindex: (
  success: 9000,
);

:export {
  primary: $primary;
  primaryLight: $primary-light;
  secondary: $secondary;
  accent: $accent;
  white: $white;
  lightGrey: $light-grey;
  shadowGrey: $shadow-grey;
  borderGrey: $border-grey;
  placeholderGrey: $placeholder-grey;
  borderButtonGrey: $border-button-grey;
  disabledGrey: $disabled-grey;
  disabledGreyBorder: $disabled-grey-border;
  black: $black;
  red: $red;
  darkBlue: $dark-blue;
  darkGreyBlue: $dark-greyblue;
  yellow: $yellow;
  yellowTransparent: $yellow-transparent;
  yellowLight: $yellow-light;
  greyText: $grey-text;
  darkGrey: $dark-grey;
  lightGreyish: $light-greyish;
  lightGrayishOrange : $light-grayish-orange;
  rotateButtonHoverPrimary: $rotate-button-hover-primary;
  rotateButtonHoverSecondary: $rotate-button-hover-secondary;
}
