#services-form-container,
#hotels-form-container,
#travels-form-container,
#building-form-container {
  width: 90%;
  border-radius: 9px;
  border: 1px solid $border-grey-container;

  @media #{$tablet} {
    width: 85%;
  }

  h6 {
    padding: 1.5rem 1.5rem 0 1.5rem;
    font-weight: 500;
  }

  .error-message {
    height: 10.6rem;
    padding: 1.5rem 0 0 1.5rem;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1.5rem;
    gap: 1rem 1.5rem;

    > div:nth-last-child(1):nth-child(odd) {
      grid-column: 1 / -1;
    }

    @media #{$tablet} {
      display: flex;
      flex-direction: column;
    }
  }
}

#user-list-paper {
  @media #{$tablet} {
    width: 85%;
  }

  @media #{$tablet} {
    border-radius: 9px;
    border: 1px solid $border-grey-container;
  }

  h6 {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    font-weight: 500;
    color: $light-grey;
  }

  .user-list-row {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    gap: 0.5rem;

    > p:nth-child(2) {
      word-break: break-all;
      text-align: right;
    }
  }

  hr {
    margin: 0 1rem;
  }
}

.user-information-table {
  width: 90%;
}

#table-container {
  width: auto;
  border-radius: 9px;
  border: 1px solid $border-grey-container;
  padding: 1.5rem;

  h6 {
    padding-bottom: 1.5rem;
    font-weight: 500;
    color: $light-grey;
  }

  thead {
    background-color: $background-color;
    color: $light-grey;

    .MuiTableCell-root {
      color: $light-grey;
      font-weight: bold;
      padding: 0.5rem;
    }
  }

  th,
  td {
    text-align: center;
    border: none;
    padding: 1rem 1rem 0 1rem;
  }
}
