.message {
  font-family: 'Titillium Web';
  color: $secondary;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  @media #{$tablet} {
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 100%;
    z-index: map-get($zindex, success);
    justify-content: center;
    align-items: center;
    margin-left: 10%;

    @media #{$tablet} {
      flex-direction: column;
      text-align: center;
      margin-left: 0;
    }
  }

  .text-container {
    max-width: 55%;

    @media #{$tablet} {
      max-width: 60%;
    }

    @media #{$mobile} {
      max-width: 80%;
    }

    @media #{$small-mobile} {
      max-width: 90%;
    }

    h1 {
      font-size: 3.8125rem;
      font-weight: 300;
      margin-bottom: 0;
      margin-top: 1rem;
      max-width: 100%;

      @media #{$tablet} {
        font-size: 2rem;
      }

      @media #{$small-mobile} {
        font-size: 1.625rem;
      }
    }

    .text {
      font-size: 1.125rem;
      font-weight: 400;
      max-width: 74%;
      margin-bottom: 2rem;

      br {
        display: block;
        content: '';
        margin-top: 1rem;
      }

      @media #{$tablet} {
        max-width: 100%;
        font-size: 0.875rem;
      }
    }

    .payment-buttons-container {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 80%;

      @media #{$tablet} {
        max-width: 100%;
        justify-content: space-evenly;
      }
    }

    .button-wrap {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 1rem;
      font-family: 'Titillium Web';
      cursor: pointer;
      height: 56px;

      @media #{$tablet} {
        flex-direction: column;
        max-width: 30%;
        gap: 0.4rem;
      }

      .icon-wrap {
        margin-right: 8px;
        padding: 15px;
        border-radius: 100%;
        width: 22px;
        height: 22px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 1s;
        position: absolute;

        @media #{$tablet} {
          position: unset;
        }
      }

      .text-wrap {
        position: relative;
        left: 4rem;

        @media #{$tablet} {
          position: unset;
        }
      }
    }
  }

  .return-site-button-container {
    width: 20%;
    margin-bottom: 7%;
    margin-left: 6%;

    @media #{$tablet} {
      width: unset;
      padding: 0;
      margin-top: 15%;
      margin-left: 0;
      justify-content: center;
    }

    @media #{$mobile} {
      margin-top: 35%;
    }

    @media #{$small-mobile} {
      margin-top: 20%;
    }
  }
}
