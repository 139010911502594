@import './variables.module';
@import './common';
@import './landing';
@import './_paymentInformation';
@import './login';
@import './_paymentMethods';
@import './message';
@import './rotateButton';
@import './success';
@import './error';
@import './plexoRedirect';
@import './animations';
@import './restaurants';
@import './snackbar';
@import './maintenance';
@import './stepper';
@import './email';
@import './iframe';

body {
  margin: 0;
  font-family: 'Titillium Web', 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $background-color;
}
