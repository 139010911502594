$screen-width: 1000px;

h1 {
  line-height: 1.2;
}

h2 {
  font: normal normal bold 61px/71px Titillium Web;
}

h5 {
  font: normal normal bold 1.5rem Rubik;
  color: $black;
  margin: 0;

  @media #{$mobile} {
    font-size: 1.25rem;

    .building-apartment {
      display: none;
    }
  }
}

h6 {
  font: normal normal bold 1.125rem Rubik;
  margin: 0;
  font-weight: 500;
  color: $secondary;
}

p {
  line-height: 2;
}

.header {
  display: flex;
  align-items: flex-start;
  background-color: $white;

  .content {
    width: $screen-width;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;

    .logos {
      display: flex;
      flex-direction: row;
      gap: 20px;

      img:last-child {
        @media #{$mobile} {
          display: none;
        }
      }
    }

    @media #{$tablet} {
      width: 80%;
    }

    @media #{$mobile} {
      gap: 1rem;
    }

    @media #{$small-mobile} {
      justify-content: space-evenly;
    }
  }

  img {
    height: 40px;
    width: auto;
    margin: 0.75rem 0;
  }
}

.title {
  padding: 2.5rem 0;
  width: $screen-width;
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media #{$tablet} {
    width: 650px;
  }

  @media #{$mobile} {
    justify-content: center;
    width: unset;
    padding: 2rem 0 1.2rem 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;

    button {
      font-family: 'Rubik';
      font-size: 0.875rem;
      font-weight: 400;
    }

    @media #{$tablet} {
      display: none;
    }
  }
}

.floating-bar {
  position: fixed;
  bottom: 0;
  background-color: $white;
  gap: 1.25rem;
  padding: 1.5rem;
  width: calc(100% - 3rem);
  border-top: 1px solid $shadow-grey;
  display: none;

  @media #{$tablet} {
    display: flex;
    z-index: 999;
    flex-direction: column;
  }

  .floating-total {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .text-container {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      .primary-text {
        color: $blue;
        font: normal normal bold 1.1875rem Rubik;
      }

      .secondary-text {
        color: $light-grey;
        font: normal normal 0.75rem Rubik;
        max-width: 80%;
        line-height: 1.4;
      }
    }

    .amount-container {
      color: $blue;
      font: normal normal bold 1.3125rem Rubik;
      text-align: end;
    }
  }

  .floating-buttons {
    display: flex;
    gap: 1rem;

    .floating-continue {
      flex-grow: 1;
    }
  }
}

.screen {
  width: $screen-width;
  max-width: 80%;
  margin: 0 auto;

  @media #{$mobile} {
    width: unset;
    max-width: 85%;
  }

  .redirect-buttons {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
